<script setup>
const menuBar = [
  "A 與醫聖系統連線",
  "S 系統工具",
  "I 工具程式",
  "D 資料庫",
  "T 統計分析",
  "P 參數設定",
  "C 關懷系統",
  "F 便捷功能",
  "O 其他功能",
  "翰鼎數位專案",
  "C 仩詮網掛轉入",
  "X 結束系統",
];
</script>
<script>
export default {
  name: "DefaultLayout",
};
</script>

<template>
  <div class="bg-blue-800/80 h-screen flex flex-col">
    <div class="flex flex-row gap-3 bg-gray-300 text-black px-2">
      <router-link
        to="/"
        v-for="(item, index) of menuBar"
        :id="index"
        :key="`menuBar-${index}`"
        class="hover:underline hover:underline-offset-2"
        >{{ item }}</router-link
      >
    </div>
    <div class="flex-1">
      <div
        class="w-2/5 h-fit my-3 ml-2 p-4 shadow-3xl bg-gradient-to-b from-purple-200 via-purple-300 to-purple-400"
      >
        <span class="text-[40px] tracking-widest text-white font-bold"
          >醫聖診療系統</span
        >
      </div>
      <router-view :key="$route.path"></router-view>
    </div>

    <div class="flex flex-row gap-3 bg-gray-300 text-black px-2">
      <span>＊醫聖 醫療院所作業系統</span>
      <span>＊123344848.39393939</span>
      <span>Tel: (07)315-4932</span>
      <span>【登入者: 王小明】</span>
      <span>【預設醫師: 王小明 02】</span>
    </div>
  </div>
</template>
