<script setup></script>
<script>
export default {
  name: "EmptyLayout",
};
</script>
<template>
  <div class="h-screen flex flex-col">
    <router-view :key="$route.path"></router-view>
  </div>
</template>
