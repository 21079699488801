import { createApp } from "vue";
import "@/assets/styles/tailwind.css";
import App from "./App.vue";
import { createPinia } from "pinia";
import initRouter from "@/router";
import { addPlugins } from "@/plugins/main";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const pluginContext = {};
const pinia = createPinia();
const app = createApp(App);
app.use(pinia);

const { router } = initRouter();
app.use(router);

addPlugins(app, router, pluginContext);

app.component('VueDatePicker', VueDatePicker);

app.mount("#app");
