<template>
  <component :is="layoutcomponent"></component>
</template>
<script setup>
import DefaultLayout from "@/layouts/default.vue";
import EmptyLayout from "./layouts/empty.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";

const layoutByKey = {
  'default': DefaultLayout,
  'empty': EmptyLayout
}

const route = useRoute();
const layoutcomponent = computed(() => layoutByKey[route.meta.layout] || layoutByKey.default);
</script>
<script>
export default {
  name: "App",
};
</script>
