import { createRouter, createWebHistory } from "vue-router";
import routes from "~pages";

export default function initRouter() {
  const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/',
        redirect: '/login', // 預設跳轉到 /login
      },
      ...routes, // 其他自動生成的路由
    ],
  });
  return { router };
}
